<template>
  <div>
    <div class="home_body">
      <div class="body_one">
        <img
          class="body_bg"
          src="http://img.taojiefang.cn/Fh44Ffp2z-YBaal_yAFGIYnARW5U"
        />
        <div class="moblie_body">
          <div class="moblie_body_img">
            <router-link :to="{ name: 'Invitecode' }">
              <img src="@/assets/moble_images/logotaojiefangx.png" alt />
            </router-link>
          </div>
          <div class="head_title">一个帮你省钱的返利app</div>
          <div @click="_golink" class="head_onload">
            <div class="onload_logo">
              <img src="@/assets/moble_images/onload_logo.png" alt />
            </div>
            <span>立即下载</span>
          </div>
          <img class="inviteCode" src="@/assets/pc_images/inviteCode-1.png" />
        </div>
      </div>
      <div class="mobole_one">
        <span class="mobole_one_text">为什么选择淘街坊</span>
        <span class="problem">“</span>
        <div class="coupon_list">
          <div class="coupon_list_sort">
            <div class="coupon_list_img">
              <img src="@/assets/moble_images/get_coupon.png" alt />
            </div>
            <span>领券购物</span>
            <span>省钱高达90%</span>
          </div>
          <div class="coupon_list_sort">
            <div class="coupon_list_img">
              <img src="@/assets/moble_images/getback_coupon.png" alt />
            </div>
            <span>券后再返佣</span>
            <span>提现秒速到帐</span>
          </div>
        </div>
        <div style="margin-top: 20px" class="coupon_list">
          <div class="coupon_list_sort">
            <div class="coupon_list_img">
              <img src="@/assets/moble_images/get_firends.png" alt />
            </div>
            <span>邀请好友</span>
            <span>终身绑定</span>
          </div>
          <div class="coupon_list_sort">
            <div class="coupon_list_img">
              <img src="@/assets/moble_images/get_share.png" alt />
            </div>
            <span>一键分享</span>
            <span>轻松躺赚</span>
          </div>
        </div>
      </div>
      <div class="select_tjf">
        <span class="mobole_one_text" style="margin-top: 23px; color: #ffffff"
          >TA们都选择了淘街坊</span
        >
        <span class="problem">“</span>
        <div class="select_content" style="margin-top: 20px">
          我是一个没有上班在家带两个孩子的宝妈，开销都靠老公一个人的收入，平常除了给孩子买东西外，自己根本舍不得买，直到在朋友那边了解到了淘街坊这个APP，领券购物，自用省钱分享赚钱，邀请好友一起用，她买东西我也有佣金，边带孩子边赚钱终于可以为这个家分担一点了，好东西就应该大家分享。
          <div class="select_ing select_posleft">
            <img src="@/assets/moble_images/selcet_one.png" alt />
          </div>
        </div>
        <div class="select_content">
          我是一名刚毕业的大学生，找不到工作，在同学的推荐下我下载了淘街坊APP，通过邀请同学一起用，他们买东西能省钱，我还能获得佣金，他们再分享给他们的朋友我也有佣金，而且永久绑定我账号下，在做淘街坊的第一个月佣金就达到了1万多，现在家里都很高兴也很支持我全职做淘街坊。
          <div class="select_ing select_posright">
            <img src="@/assets/moble_images/select_two.png" alt />
          </div>
        </div>
        <div class="select_content">
          我是一名普通白领，在外租房，水电费，吃饭，基本工资就没有了，根本不敢去应酬交朋友。之后经同事介绍用了淘街坊APP这个软件，分享给身边同学朋友用，永久绑定我账号，他们购物省钱，我还能获得佣金，现在每个月都能通过返佣躺赚8千块以上，希望把这个APP介绍给大家一起轻松赚钱。
          <div class="select_ing select_posleft">
            <img src="@/assets/moble_images/select_three.png" alt />
          </div>
        </div>
      </div>
      <div class="footer_more">
        <span class="more_coupon">获取更多优惠</span>
        <span class="problem" style="margin-top: 12px">“</span>
        <div class="bac_content">
          <div class="top_content">
            <img
              class="top_img"
              src="@/assets/moble_images/footer_one.png"
              alt
            />
            <div class="top_text">
              <div class="text_head">方法一</div>
              <div class="text_footer">
                打开微信搜索
                <span>“淘街坊特卖”</span>，在搜索 结果中选中公众号，点击
                <span>「关注公众号」</span>
                即可
              </div>
            </div>
          </div>
          <div class="line_content"></div>
          <div class="top_content">
            <img
              class="top_img"
              src="@/assets/moble_images/footer_two.png"
              alt
            />
            <div class="top_text">
              <div class="text_head">方法二</div>
              <div class="text_footer">
                1.长按下方二维码，保存到手机相册
                <br />2.打开 <span>「微信扫一扫」</span>，点击右下角
                <br />
                <span>「相册」</span>,选择刚才保存的图片，扫描识别
              </div>
            </div>
          </div>
          <div class="QRCode">
            <img class="tjf_code" src="@/assets/moble_images/code.png" alt />
          </div>
        </div>
      </div>
    </div>
		<van-overlay :show="show" @click="show = false">
			<self-mask></self-mask>
		</van-overlay>
    <div class="moblie_footer">
      <a href="https://beian.miit.gov.cn" target="_blank">粤ICP备18020185号</a>
      <span>广州全民互娱网络科技有限公司 版权所有</span>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import VueMeta from "vue-meta";
import { getConfig } from "@/api/wxconfig";
import {addEvent} from '@/api/event'
import {isMicroMessenger, isAndroid, isIOS, isMobile} from '@/utils/user-agent'
import {Overlay} from 'vant'
import SelfMask from '@/views/alipage/ToTaoBao'

Vue.use(Overlay);
Vue.use(VueMeta);

export default {
	components: {
		SelfMask
	},
  name: "App",
  metaInfo: {
    title: "淘街坊",
    meta: [
      { name: "keywords", content: "优惠,街坊,淘街坊,淘街坊APP,下载淘街坊" },
      {
        name: "description",
        content:
          "淘街坊省钱，淘街坊赚钱，下载淘街坊APP，加入淘街坊还送VIP，自己购买或者分享给朋友，可得90%的优惠哦！",
      },
    ],
  },
  data() {
    return {
			show: false
		};
  },
  created() {
		this._addEvent();
    this.loadData();
  },
  mounted() {
    document.dispatchEvent(new Event("render-event"));
  },
  methods: {
    loadData() {
      let params = {
        url: "http ://www.taojiefang.cn/home_moblie",
        apis: [
          "updateAppMessageShareData",
          "updateTimelineShareData",
          "onMenuShareTimeline",
          "onMenuShareAppMessage",
        ],
      };
      getConfig(params)
        .then((res) => {
          if (res.errCode === 0) {
            this.$wechat.config(res.data);
            this._initShare();
          } 
        })
        .catch((err) => {
          console.log(error);
        });
    },
    _golink() {
      if (isIOS) {
        var ios_link =
          "https://apps.apple.com/cn/app/%E6%B7%98%E8%A1%97%E5%9D%8A/id1444751745";
        window.location.href = ios_link;
        return "ios";
      } else {

				// 安卓微信浏览器需要在默认浏览器打开
				// if(isMicroMessenger) {
				// 	this.show = true
				// 	return false;
				// }

        var andriod_link = 'http://app.taojiefang.cn/app_android/download';
        // "https://a.app.qq.com/o/simple.jsp?pkgname=com.quanminhuyu.taojiefang";
        window.location.href = andriod_link;
        return "andriod";
      }
    },
    _initShare() {
      this._shareTimeline();
      this._shareAppMessage();
      this._updateTimelineShareData();
      this._updateAppMessageShareData();
    },
    // 右上角菜单分享朋友圈
    _shareTimeline() {
      let that = this;
      this.$wechat.onMenuShareTimeline({
        title: "下载淘街坊APP，领饿了么会员", // 分享标题
        link: "http ://www.taojiefang.cn/home_moblie",
        imgUrl: "http://img.quanminhuyu.com/Fu7V2LAaFHvNb5bO6-jUWZo5aH5f", // 分享图标
        success: function () {},
      });
    },
    // 右上角菜单分享给朋友
    _shareAppMessage() {
      let that = this;
      this.$wechat.onMenuShareAppMessage({
        title: "下载淘街坊APP，领饿了么会员", // 分享标题
        desc: "一个自用省钱,分享赚钱的APP，点击下载领取更多优惠！", // 分享描述
        link: "http ://www.taojiefang.cn/home_moblie",
        imgUrl: "http://img.quanminhuyu.com/Fu7V2LAaFHvNb5bO6-jUWZo5aH5f", // 分享图标
        success: function () {},
      });
    },
    // 分享朋友圈
    _updateTimelineShareData() {
      let that = this;
      this.$wechat.ready(function () {
        that.$wechat.updateTimelineShareData({
          title: "下载淘街坊APP，领饿了么会员", // 分享标题
          link: "http ://www.taojiefang.cn/home_moblie",
          imgUrl: "http://img.quanminhuyu.com/Fu7V2LAaFHvNb5bO6-jUWZo5aH5f", // 分享图标
          success: function () {},
        });
      });
    },
    // 分享朋友
    _updateAppMessageShareData() {
      let that = this;
      this.$wechat.ready(function () {
        that.$wechat.updateAppMessageShareData({
          title: "下载淘街坊APP，领饿了么会员", // 分享标题
          desc: "一个自用省钱,分享赚钱的APP，点击下载领取更多优惠！", // 分享描述
          link: "http ://www.taojiefang.cn/home_moblie",
          imgUrl: "http://img.quanminhuyu.com/Fu7V2LAaFHvNb5bO6-jUWZo5aH5f", // 分享图标
          success: function () {},
        });
      });
		},
		_addEvent() {
			const query = this.$route.query || null
			if(query.fromkey) {
				const os = isMobile?(isAndroid?'adroid':'ios'):'pc'
				addEvent({key: query.fromkey, os: os,  }).then().catch()
			}
		}
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}
.home_body {
  width: 100%;
  overflow: hidden;
  position: relative;
}
.body_one {
  position: relative;
  z-index: 0;
}
.body_bg {
  width: 100%;
  height: 514px;
  position: relative;
  z-index: 0;
}
.moblie_body {
  position: absolute;
  z-index: 10;
  top: 30px;
  left: 50%;
  transform: translateX(-50%);
}
.moblie_body_img {
  width: 108px;
  height: 30px;
  margin: 0 auto;
}
.moblie_body_img img {
  width: 100%;
  height: 100%;
}
.head_onload {
  margin: 18px auto 0;
  width: 151px;
  height: 36px;
  background: #fff;
  border-radius: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.head_onload span {
  font-size: 17px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  margin-left: 5px;
  color: #789cfd;
}
.inviteCode {
  width: 136px;
  height: 15px;
  margin: 10px 24px 0;
}
.onload_logo {
  width: 17px;
  height: 17px;
}
.onload_logo img {
  width: 100%;
  height: 100%;
}
.head_title {
  width: 100%;
  padding: 5px 0;
  text-align: center;
  font-family: PingFangSC-Medium, PingFang SC;
  font-size: 17px;
  line-height: 1;
  color: #ffffff;
  border-bottom: 1px solid #ffffff;
}
.moblie_download {
  width: 140px;
  height: 34px;
  background: #f80375;
  border-radius: 18.6px;
  margin-top: 28.5px;
}
.download_body {
  overflow: hidden;
  width: 70%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  justify-items: auto;
}
.download_img {
  flex: 20%;
  margin-top: 7%;
}
.download_img img {
  width: 20px;
  height: 20px;
}
.download_text {
  text-align: center;
  flex: 80%;
  margin-top: 7%;
  font-size: 15.6px;
  color: #ffffff;
}
a {
  text-decoration: none;
  color: #fff;
}
.anzhuo_one {
  flex: 77%;
  margin-top: 5%;
}
.one_anzhuo {
  margin-top: 14.4px;
}
.moblie_text {
  overflow: hidden;
  width: 100%;
  text-align: center ；;
}
.moblie_text span {
  display: block;
}
.text_one {
  font-size: 24px;
  color: #fea32a;
}

.pos_one {
  top: 42%;
  left: 34%;
  text-align: center;
}
.pos_two {
  top: 62%;
  left: 21%;
  text-align: center;
}
.pos_three {
  top: 82%;
  left: 20%;
  text-align: center;
}
.moblie_footer {
  padding-top: 12px;
  height: 60px;
  background: #333333;
  text-align: center;
}
.moblie_footer span {
  line-height: 1;
  margin-top: 5px;
  display: block;
  font-size: 9px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
}
.footer_polic {
  overflow: hidden;
  text-align: center;
}
.polic_img {
  width: 12px;
  height: 12px;
  float: left;
}
.polic_img img {
  width: 100%;
  height: 100%;
}
.polic_text {
  float: left;
  font-family: PingFangSC-Regular;
  font-size: 8.8px;
  color: #ffffff;
}
.top {
  height: 50px;
}
.mobole_one_text {
  font-family: PingFangSC-Medium, PingFang SC;
  text-align: center;
  display: block;
  width: 100%;
  margin: 34px auto 10px;
  font-size: 18px;
  color: #495dfd;
}
.problem {
  display: block;
  width: 100%;
  font-size: 25px;
  color: #e2e5ff;
  text-align: center;
}
.coupon_list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 84px;
}
.coupon_list_sort {
  width: 74px;
}
.coupon_list_sort span {
  display: block;
  width: 100%;
  text-align: center;
  font-size: 12px;
  color: #6274ff;
  letter-spacing: -0.09px;
}
.coupon_list_img {
  width: 54px;
  height: 54px;
  background: #ffffff;
  box-shadow: 0 0 6px 0 #bfdafc;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  margin: 0 auto 8.8px;
}
.coupon_list_img img {
  width: 54px;
  height: 54px;
}
.select_tjf {
  width: 100%;
  margin-top: 27px;
  background: url("../../../assets/download/bg-blue.png") no-repeat;
  background-size: 100% 100%;
  padding: 1px 0;
}
.select_content {
  border-radius: 4px;
  margin: 0 21px 34px;
  padding: 28px 10px 12px 10px;
  background: #ffffff;
  font-size: 12px;
  color: #495dfd;
  position: relative;
  line-height: 17px;
}
.select_posleft {
  top: -25px;
  left: 0;
}
.select_posright {
  top: -25px;
  right: 0;
}
.select_ing {
  position: absolute;
  width: 157px;
  height: 44px;
}
.select_ing img {
  width: 100%;
  height: 100%;
}
.footer_more {
  padding-top: 25px;
  margin-bottom: 20px;
}
.more_coupon {
  width: 100%;
  display: block;
  text-align: center;
  height: 18px;
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #495dfd;
}
.bac_content {
  width: 334px;
  height: 394px;
  background-image: url("../../../assets/moble_images/footer_bac.png");
  background-position: 0 0;
  background-size: 100% 100%;
  margin: 0 auto;
  padding-top: 36px;
}
.top_content {
  /* margin: 27px auto; */
  display: flex;
  align-items: center;
  justify-content: center;
}
.top_img {
  width: 90px;
  height: 74px;
}
.text_head {
  width: 72px;
  height: 24px;
  line-height: 24px;
  background: #495dfd;
  border-radius: 11.5px;
  text-align: center;
  font-size: 16px;
  color: #ffffff;
}
.text_footer {
  width: 222px;
  font-size: 12px;
  color: #495dfd;
  margin-top: 8px;
}
.text_footer span {
  font-size: 15px;
  color: #f80375;
}
.line_content {
  width: 322px;
  margin: 14px 0 15px 0;
  border: 1px dashed #495dfd;
}
.QRCode {
  width: 110px;
  height: 110px;
  background: #ffffff;
  border-radius: 2px;
  margin: 16px auto 0;
  display: flex;
  border: 1px solid #f5f5f5;
  justify-content: center;
  align-items: center;
}
.tjf_code {
  width: 95px;
  height: 95px;
  display: flex;
  justify-content: center;
}
.tc {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 20px;
}
.join_btn {
  width: 150px;
  text-decoration: none;
  border-radius: 15px;
  padding: 12px;
  font-size: 16px;
  color: #4a5dfd;
  border: 0;
  background-color: #ffffff;
  outline: none;
  cursor: pointer;
  text-align: center;
}
</style>
